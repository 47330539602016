<template>
    <div>
        <div>
            <h3>Discover New Anime</h3>
            <h4 v-if="this.anime.title">{{ this.anime.title }}</h4>
            <img :src=this.anime.imageUrl style="width: 90%;" />
            <p v-if="this.anime.synopsis">Description: {{ this.anime.synopsis }}</p>
            <p v-if="this.anime.airedFrom">Aired From: {{ this.anime.airedFrom }}</p>
            <p v-if="this.anime.airedTo">Aired To: {{ this.anime.airedTo }}</p>
            <p v-if="this.anime.rank">Rank: {{ this.anime.rank }}</p>
            <p v-if="this.anime.rating">Rating: {{ this.anime.rating }}</p>
            <p v-if="this.anime.trailer"><a :href=this.anime.trailer>Trailer</a></p>
        </div>
        <p>*Anime data pulled from Jikan API</p>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    name: "AnimeDiscovery",
    data() {
        return {
            anime: ""
        }
    },
    methods: {
        async getRandomAnime() {
            let successfulAnime = this.makeHttpCall();
            while (!successfulAnime) {
                successfulAnime = this.makeHttpCall();
            }
        },
        async makeHttpCall() {
            try {
                let response = await axios.get('https://api.jikan.moe/v4/random/anime');
                //console.log("Anime response: ", response.data.data);

                let rawJson = response.data.data; // Access the 'data' property

                // Extracting information from the response
                let title = rawJson.title_english || rawJson.title; // Fallback to the original title if English title is not available
                let imageUrl = rawJson.images.jpg.large_image_url; // Get the JPG image URL
                let synopsis = rawJson.synopsis; // Get the synopsis
                let episodes = rawJson.episodes;
                let rank = rawJson.rank;
                let trailer = rawJson.trailer.url;
                let rating = rawJson.rating;
                let airedFromRaw = rawJson.aired.from;
                let airedToRaw = rawJson.aired.to;
                console.log('rating: ' + rating);
                let airedFrom = '';
                let airedTo = '';
                if (airedFromRaw) {
                    let temp = Date.parse(airedFromRaw);
                    let tempDate = new Date(0);
                    tempDate.setUTCSeconds(temp);
                    airedFrom = tempDate.toLocaleString([], {
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit',
                    })
                }
                if (airedToRaw) {
                    let temp = Date.parse(airedToRaw);
                    let tempDate = new Date(0);
                    tempDate.setUTCSeconds(temp);
                    airedTo = tempDate.toLocaleString([], {
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit',
                    })
                }

                if (!rating.toLowerCase().includes('hentai') && !rating.toLowerCase().includes('nudity')) {
                    var anime = { title, imageUrl, synopsis, episodes, rank, trailer, airedFrom, airedTo, rating };
                    this.anime = anime;
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                console.error("Error fetching animes: ", error);
            }
        }
    },
    mounted() {
        this.getRandomAnime()
        setInterval(this.getRandomAnime, 300000);
    }
}
</script>