<template>
    <div>
        <h3>Chuck Norris Fact of the Day</h3>
        <p>{{ joke }}</p>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    name: "ChuckNorris",
    data() {
        return {
            joke: ""
        }
    },
    methods: {
        async getJoke() {
            try {
                let response = await axios.get('https://api.chucknorris.io/jokes/random');
                console.log("Chuck: " + response.data.value);
                this.joke = response.data.value;
            } catch (error) {
                console.log('Chuck norris error: ' + error);
            }
        },
    },
    mounted() {
        this.getJoke()
        setInterval(this.getJoke, 600000);
    }
}
</script>