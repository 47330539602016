<template>
    <div>
        <h2>{{ dateAndTime }}</h2>
        <span style="display: inline-flex; width: 100%; justify-content: center;">
            <h2>{{ weather }}</h2><img :src="icon" />
        </span>
        <div>
            <p v-if="this.zipCode">*Weather for Zip Code {{ this.zipCode }}</p>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import Constants from '../data/contants'

export default {
    name: "DateTimeTemp",
    data() {
        return {
            dateAndTime: "",
            weather: "",
            icon: "",
            zipCode: ""
        }
    },
    methods: {
        getDateAndTime() {
            const today = new Date();
            const time = today.toLocaleString([], {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
            const date = today.toLocaleString([], {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
            });
            this.dateAndTime = time + " " + date;
        },
        async getWeather() {
            let apiKey = this.$cookies.get(Constants.WEATHER_API_KEY);
            if (apiKey) {
                try {
                    const weatherUrl = 'https://api.weatherapi.com/v1/current.json';
                    const auth = {
                        headers: {
                            key: apiKey,
                        }
                    }
                    const response = await axios.get(weatherUrl, {
                        headers: auth.headers,
                        params: {
                            q: this.zipCode
                        }
                    });

                    var temp = response.data.current.temp_f + String.fromCharCode(176) + 'F';
                    var icon = response.data.current.condition.icon;
                    console.log('icon: ' + icon);
                    console.log("temp: " + temp);
                    this.weather = temp;
                    this.icon = icon;
                } catch (error) {
                    console.log('Error getting weather: ' + error);
                }
            }
            else {
                console.log('NOT fetching Weather (api key)');
            }
        }
    },
    mounted() {
        this.zipCode = this.$cookies.get(Constants.ZIP_COOKIE_NAME);

        if (this.zipCode) {
            this.getWeather()
            setInterval(this.getWeather, 300000);
        } else {
            this.temp = "N/A";
            console.log('NOT fetching Weather (zip code)');
        }

        this.getDateAndTime()
        setInterval(this.getDateAndTime, 1000);
    }
}
</script>