<template>
    <div class="mainboard">
        <div class="content">
            <CoinbaseBoard class="board" />
            <StockApiBoard class="board" />
            <DateTimeAndTemp class="board" />
            <HolidayCalendar class="board" />
            <ChuckNorris class="board" />
            <AnimeDiscovery class="board" />
            <SpaceFlight class="board" />
            <CurrentNews class="board" />
            <FoodPicture class="board" />
            <GameDeals class="board" />
            <FreeGames class="board" />
            <GameGiveaways class="board" />
            <PubgBoard class="board" />
        </div>
        <IpAddress class="footer" />
    </div>
</template>

<script>
import CoinbaseBoard from './CoinbaseBoard.vue';
import DateTimeAndTemp from './DateTimeAndTemp.vue';
import ChuckNorris from './ChuckNorris.vue';
import AnimeDiscovery from './AnimeDiscovery.vue';
import IpAddress from './IpAddress.vue';
import StockApiBoard from './StockApiBoard.vue';
import HolidayCalendar from './HolidayCalendar.vue';
import SpaceFlight from './SpaceFlight.vue';
import CurrentNews from './CurrentNews.vue';
import FoodPicture from './FoodPicture.vue';
import GameDeals from './GameDeals.vue';
import FreeGames from './FreeGames.vue';
import GameGiveaways from './GameGiveaways.vue';
import PubgBoard from './PubgBoard.vue';

export default {
    name: 'MainBoard',
    props: {
    },
    components: {
        CoinbaseBoard,
        DateTimeAndTemp,
        ChuckNorris,
        AnimeDiscovery,
        IpAddress,
        StockApiBoard,
        HolidayCalendar,
        SpaceFlight,
        CurrentNews,
        FoodPicture,
        GameDeals,
        FreeGames,
        GameGiveaways,
        PubgBoard
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mainboard {
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    min-height: 100vh;
    /* Ensure it takes up full height of the viewport */
}

.content {
    flex: 1;
    /* Allow content to grow and take available space */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* Distributes space evenly */
}

.board {
    flex: 1 1 calc(33.333% - 10px);
    /* Each board takes up 1/3 of the row */
    height: 400px;
    overflow-y: scroll;
    background-color: rgb(14, 173, 242);
    border: 2px solid rgba(0, 0, 0, 0.8);
    padding: 5px;
    font-size: 25px;
    text-align: center;
    margin: 5px;
    box-sizing: border-box;
    /* Ensures padding and border are included in width */
}

.footer {
    background-color: rgb(240, 240, 240);
    /* Footer background color */
    padding: 10px;
    text-align: center;
    border-top: 2px solid rgba(0, 0, 0, 0.8);
}

@media (max-width: 800px) {
    .board {
        flex: 1 1 calc(50% - 20px);
        /* For smaller screens, make two columns */
    }
}

@media (max-width: 500px) {
    .board {
        flex: 1 1 100%;
        /* For very small screens, make it a single column */
    }
}
</style>