<template>
    <div>
        <h3>Free to Play Game</h3>
        <div>
            <h4 v-if="this.game.title">{{ this.game.title }}</h4>
            <img :src=this.game.thumbnail style="width: 90%;" />
            <p v-if="this.game.short_description">Description: {{ this.game.short_description }}</p>
            <p v-if="this.game.release_date">Release Date: {{ this.game.release_date }}</p>
            <p v-if="this.game.publisher">Publisher: {{ this.game.publisher }}</p>
            <p v-if="this.game.platform">Platform: {{ this.game.platform }}</p>
            <p v-if="this.game.genre">Genre: {{ this.game.genre }}</p>
            <p v-if="this.game.game_url"><a :href=this.game.game_url>Link</a></p>
        </div>
        <p>*Game data pulled from FreeToGame</p>
    </div>
</template>


<script>
import Constants from '@/data/contants';
import axios from 'axios';

export default {
    name: "FreeGames",
    data() {
        return {
            game: "",
            allGames: []
        }
    },
    methods: {
        async getGames() {
            let rapidApiKey = this.$cookies.get(Constants.RAPID_API_COOKIE_NAME);
            if (rapidApiKey) {
                //console.log(rapidApiKey);
                const options = {
                    method: 'GET',
                    url: 'https://free-to-play-games-database.p.rapidapi.com/api/games',
                    headers: {
                        'x-rapidapi-key': rapidApiKey,
                        'x-rapidapi-host': 'free-to-play-games-database.p.rapidapi.com'
                    }
                };
                try {
                    let response = await axios.request(options);
                    let rawJson = response.data;
                    //console.log("Games: " + rawJson);
                    this.allGames = rawJson;
                    this.getRandomGame();
                } catch (error) {
                    console.log('Error getting free games: ' + error);
                }
            } else {
                console.log('NOT fetching Free Games');
            }
        },
        getRandomGame() {
            let randomNum = Math.floor(Math.random() * (this.allGames.length - 1))
            this.game = this.allGames[randomNum];
        }
    },
    mounted() {
        this.getGames()
        setInterval(this.getRandomGame, 600000);
    }
}
</script>