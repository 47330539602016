<template>
    <div>
        <h3>Random Food Image</h3>
        <img :src="this.food" style="width: 90%;" />
    </div>
</template>


<script>
import axios from 'axios';

export default {
    name: "FoodPciture",
    data() {
        return {
            food: ""
        }
    },
    methods: {
        async getFood() {
            try {
                let response = await axios.get('https://foodish-api.com/api/');
                console.log("Food: " + response.data.image);
                this.food = response.data.image;
            } catch (error) {
                console.log('Food error: ' + error);
            }
        },
    },
    mounted() {
        this.getFood()
        setInterval(this.getFood, 600000);
    }
}
</script>