<template>
    <div>
        <div>
            <label>WeatherApi API Key</label>
            <input type="text" v-model="weatherApiKey" @input="handleInputChange('weather')" />
        </div>
        <div>
            <label>Your Zip Code</label>
            <input type="text" placeholder="Zip Code" v-model="zipCode" @input="handleInputChange('zip')" />
        </div>

        <div>
            <label>Enter a comma delimited list of crypto tickers</label>
            <input type="text" placeholder="BTC,ETH..." v-model="coinbaseCryptos"
                @input="handleInputChange('coinbase')" />
        </div>
        <div>
            <label>Enter your Alpha Vantage API Key</label>
            <input type="text" v-model="alphaKey" @input="handleInputChange('alpha')" />
            <label>Enter a comma limited of stock tickers</label>
            <input type="text" v-model="stockTickers" @input="handleInputChange('stocks')" />
        </div>
        <div>
            <label>Rapid API Key</label>
            <input type="text" v-model="rapidApiKey" @input="handleInputChange('rapid')" />
        </div>
        <div>
            <label>Abstract (Holidays) API Key</label>
            <input type="text" v-model="abstractApiKey" @input="handleInputChange('abstract')" />
        </div>
        <div>
            <label>Enter your PUBG API Key</label>
            <input type="text" v-model="pubgApikey" @input="handleInputChange('pubgapi')" />
            <label>Enter your PUBG palyer name (case sensitive)</label>
            <input type="text" v-model="pubgplayerName" @input="handleInputChange('pubgplayer')" />
        </div>
        <div>
            <label>Currents (News) API Key</label>
            <input type="text" v-model="currentsApiKey" @input="handleInputChange('currents')" />
        </div>
    </div>
    <div><input type="button" @click="ClearCookies" value="Clear Cookies" /> </div>
</template>

<script>
import Constants from '../data/contants';

export default {
    name: "SetupConfiguration",
    data() {
        return {
            coinbaseCryptos: "",
            zipCode: "",
            weatherApiKey: "",
            alphaKey: "",
            stockTickers: "",
            rapidApiKey: "",
            abstractApiKey: "",
            pubgApikey: "",
            pubgplayerName: "",
            currentsApiKey: "",
        }
    },
    methods: {
        handleInputChange(inputName) {
            // Your logic here
            console.log('Input changed:' + inputName);
            switch (inputName) {
                case "coinbase":
                    //console.log(Constants.COINBASE_COOKIE_NAME);
                    this.$cookies.set(Constants.COINBASE_COOKIE_NAME, this.coinbaseCryptos, -1);
                    break;

                case "weather":
                    //console.log(Constants.WEATHER_COOKIE_NAME);
                    this.$cookies.set(Constants.WEATHER_API_KEY, this.weatherApiKey, -1);
                    break;

                case "zip":
                    this.$cookies.set(Constants.ZIP_COOKIE_NAME, this.zipCode, -1);
                    break;

                case "alpha":
                    this.$cookies.set(Constants.ALPHA_COOKIE_NAME, this.alphaKey, -1);
                    break;

                case "stocks":
                    this.$cookies.set(Constants.STOCKS_COOKIE_NAME, this.stockTickers, -1)
                    break;

                case "rapid":
                    this.$cookies.set(Constants.RAPID_API_COOKIE_NAME, this.rapidApiKey, -1)
                    break;

                case "abstract":
                    this.$cookies.set(Constants.ABSTRACT_HOLIDAY_API_KEY, this.abstractApiKey, -1)
                    break;

                case "pubgapi":
                    this.$cookies.set(Constants.PUBG_API_COOKIE_NAME, this.pubgApikey, -1)
                    break;

                case "pubgplayer":
                    this.$cookies.set(Constants.PUBG_PLAYER_COOKIE_NAME, this.pubgplayerName, -1)
                    break;

                case "currents":
                    this.$cookies.set(Constants.CURRENTS_NEWS_COOKIE_NAME, this.currentsApiKey, -1)
                    break;

                default:
                    break;
            }
        },

        ClearCookies() {
            console.log("clearing all cookies");
            this.$cookies.remove(Constants.COINBASE_COOKIE_NAME);
            this.$cookies.remove(Constants.WEATHER_API_KEY);
            this.$cookies.remove(Constants.ZIP_COOKIE_NAME);
            this.$cookies.remove(Constants.STOCKS_COOKIE_NAME);
            this.$cookies.remove(Constants.ALPHA_COOKIE_NAME);
            this.$cookies.remove(Constants.RAPID_API_COOKIE_NAME);
            this.$cookies.remove(Constants.ABSTRACT_HOLIDAY_API_KEY);
            this.$cookies.remove(Constants.PUBG_API_COOKIE_NAME);
            this.$cookies.remove(Constants.PUBG_PLAYER_COOKIE_NAME);
            this.$cookies.remove(Constants.CURRENTS_NEWS_COOKIE_NAME);
            console.log("cleared all cookies");
        }
    },
    mounted() {
        this.coinbaseCryptos = this.$cookies.get(Constants.COINBASE_COOKIE_NAME);
        this.weatherApiKey = this.$cookies.get(Constants.WEATHER_API_KEY);
        this.zipCode = this.$cookies.get(Constants.ZIP_COOKIE_NAME);
        this.stockTickers = this.$cookies.get(Constants.STOCKS_COOKIE_NAME);
        this.alphaKey = this.$cookies.get(Constants.ALPHA_COOKIE_NAME);
        this.rapidApiKey = this.$cookies.get(Constants.RAPID_API_COOKIE_NAME);
        this.abstractApiKey = this.$cookies.get(Constants.ABSTRACT_HOLIDAY_API_KEY);
        this.pubgApikey = this.$cookies.get(Constants.PUBG_API_COOKIE_NAME);
        this.pubgplayerName = this.$cookies.get(Constants.PUBG_PLAYER_COOKIE_NAME);
        this.currentsApiKey = this.$cookies.get(Constants.CURRENTS_NEWS_COOKIE_NAME);
    }
}
</script>