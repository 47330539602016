<template>
    <div>
        <h3>Game Giveaways</h3>
        <div>
            <h4 v-if="this.giveaway.title">{{ this.giveaway.title }}</h4>
            <img :src=this.giveaway.thumbnail style="width: 90%;" />
            <p v-if="this.giveaway.description">Description: {{ this.giveaway.description }}</p>
            <p v-if="this.giveaway.published_date">Published Date: {{ this.giveaway.published_date }}</p>
            <p v-if="this.giveaway.end_date">End Date: {{ this.giveaway.end_date }}</p>
            <p v-if="this.giveaway.platforms">Platform: {{ this.giveaway.platforms }}</p>
            <p v-if="this.giveaway.type">Type: {{ this.giveaway.type }}</p>
            <p v-if="this.giveaway.open_giveaway_url"><a :href=this.giveaway.open_giveaway_url>Go to Giveaway</a></p>
        </div>
        <p>*Giveaway data pulled from Gamer Power</p>
    </div>
</template>


<script>
import Constants from '@/data/contants';
import axios from 'axios';

export default {
    name: "FreeGames",
    data() {
        return {
            giveaway: "",
            allGiveaways: []
        }
    },
    methods: {
        async getGiveaways() {
            let rapidApiKey = this.$cookies.get(Constants.RAPID_API_COOKIE_NAME);
            if (rapidApiKey) {
                //console.log(rapidApiKey);
                const options = {
                    method: 'GET',
                    url: 'https://gamerpower.p.rapidapi.com/api/giveaways',
                    headers: {
                        'x-rapidapi-key': rapidApiKey,
                        'x-rapidapi-host': 'gamerpower.p.rapidapi.com'
                    }
                };
                try {
                    let response = await axios.request(options);
                    let rawJson = response.data;
                    //console.log("Games: " + rawJson);
                    this.allGiveaways = rawJson;
                    this.getRandomGiveaway();
                } catch (error) {
                    console.log('Error getting game giveaways: ' + error);
                }
            }
            else {
                console.log('NOT fetching Game Giveaways');
            }
        },
        getRandomGiveaway() {
            let randomNum = Math.floor(Math.random() * (this.allGiveaways.length - 1))
            this.giveaway = this.allGiveaways[randomNum];
            //console.log('Giveaway: ' + this.giveaway);
        }
    },
    mounted() {
        this.getGiveaways()
        setInterval(this.getRandomGiveaway, 600000);
    }
}
</script>