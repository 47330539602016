<template>
    <div>
        <h2>{{ welcome }}</h2>
        <ul v-if='portfolio.length > 0'>
            <li v-for="p in portfolio" :key="p">
                <div>
                    <p>{{ p.crypto }} - {{ p.price }}</p>
                </div>
            </li>
        </ul>
        <p v-else>N/A</p>
    </div>
</template>

<script>
import axios from 'axios';
import Constants from '../data/contants';

export default {
    name: "CoinbaseBoard",
    data() {
        return {
            welcome: "Coinbase Crypto Prices",
            cryptos: ["BTC", "ETH", "ALGO", "SOL"],
            portfolio: [],
        }
    },
    methods: {
        async getPrices() {
            try {
                //console.log("api calls");
                let tempPort = [];
                for (const element of this.cryptos) {
                    const response = await axios.get(`https://api.coinbase.com/v2/prices/${element}-USD/spot`);
                    let portfolio = response.data;
                    //console.log(portfolio)
                    tempPort.push({ "crypto": portfolio.data.base.toUpperCase(), "price": `$${portfolio.data.amount}` });
                    //console.log(tempPort);
                }
                this.portfolio = tempPort;
            } catch (error) {
                console.log(error);
            }
        }
    },
    created() {
        let cookieList = this.$cookies.get(Constants.COINBASE_COOKIE_NAME);

        // Get the crypto tickers from the cookie if it exists
        if (cookieList) {
            let splitArray = cookieList.split(",");
            if (splitArray.length > 0) {
                this.cryptos = [];
            }
            splitArray.forEach(element => {
                //console.log(element);
                this.cryptos.push(element.trim());
            });
        }

        this.getPrices();
        setInterval(this.getPrices, 300000);
    }
}
</script>