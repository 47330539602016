<template>
    <div>
        <h2>Stock Prices</h2>
        <ul v-if='prices.length > 0'>
            <li v-for="p in prices" :key="p">
                <div>
                    <p>{{ p.stock }} - {{ p.price }}</p>
                </div>
            </li>
        </ul>
        <p v-else>N/A</p>
    </div>
</template>

<script>
import axios from 'axios';
import Constants from '../data/contants';

export default {
    name: "StocksBoard",
    data() {
        return {
            stocks: ["FDS", "MSFT", "GOOG", "AAPL", "AMD", "INTC", "NVDA", "AMZN", "GME"],
            prices: []
        }
    },
    async created() {
        let apiKey = this.$cookies.get(Constants.ALPHA_COOKIE_NAME);
        if (apiKey) {
            let cookieList = this.$cookies.get(Constants.STOCKS_COOKIE_NAME);

            // Get the stock tickers from the cookie if it exists
            if (cookieList) {
                let splitArray = cookieList.split(",");
                if (splitArray.length > 0) {
                    this.stocks = [];
                }
                splitArray.forEach(element => {
                    //console.log(element);
                    this.stocks.push(element.trim());
                });
            }

            try {
                console.log("stock api calls");
                for (const element of this.stocks) {
                    const response = await axios.get(`https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${element}&apikey=${apiKey}`);
                    const data = response.data;
                    //console.log(data);

                    let globalQuote = data['Global Quote'];
                    if (globalQuote) {
                        let price = globalQuote['05. price'];
                        this.prices.push({ "stock": element.toUpperCase(), "price": "$" + price });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log('NOT fetching Stocks');
        }
    }
}
</script>