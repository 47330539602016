const COINBASE_COOKIE_NAME = "coinbasecookie";
const WEATHER_API_KEY = "weathercookie";
const ZIP_COOKIE_NAME = "zipcookie";
const ALPHA_COOKIE_NAME = "alphacookie";
const STOCKS_COOKIE_NAME = "stockscookie";
const RAPID_API_COOKIE_NAME = "rapidcookie";
const PUBG_API_COOKIE_NAME = "pubgcookie";
const PUBG_PLAYER_COOKIE_NAME = "playercookie";
const ABSTRACT_HOLIDAY_API_KEY = "abstractcookie";
const CURRENTS_NEWS_COOKIE_NAME = "currentscookie";

export default {
  COINBASE_COOKIE_NAME: COINBASE_COOKIE_NAME,
  ALPHA_COOKIE_NAME: ALPHA_COOKIE_NAME,
  STOCKS_COOKIE_NAME: STOCKS_COOKIE_NAME,
  WEATHER_API_KEY: WEATHER_API_KEY,
  ZIP_COOKIE_NAME: ZIP_COOKIE_NAME,
  RAPID_API_COOKIE_NAME: RAPID_API_COOKIE_NAME,
  PUBG_API_COOKIE_NAME: PUBG_API_COOKIE_NAME,
  PUBG_PLAYER_COOKIE_NAME: PUBG_PLAYER_COOKIE_NAME,
  ABSTRACT_HOLIDAY_API_KEY: ABSTRACT_HOLIDAY_API_KEY,
  CURRENTS_NEWS_COOKIE_NAME: CURRENTS_NEWS_COOKIE_NAME,
};
