<template>
    <div>
        <h3>Holidays Today</h3>
        <ul>
            <li v-for="holiday in holidays" :key="holiday">
                {{ holiday.date }} - {{ holiday.name }}
            </li>
        </ul>
    </div>
</template>


<script>
import axios from 'axios';
import Constants from '@/data/contants';

export default {
    name: "HolidayCalendar",
    data() {
        return {
            holidays: []
        }
    },
    methods: {
        getHolidays() {
            const currentDate = new Date();

            // Get the day, month, and year
            for (let index = 0; index < 1; index++) {

                const day = currentDate.getDate() + index; // Numeric day of the month (1-31)
                const month = currentDate.getMonth() + 1; // Numeric month (0-11, so we add 1)
                const year = currentDate.getFullYear(); // Numeric year (e.g., 2024)

                //setTimeout(this.MakeHttpCall(day, month, year), 1000);
                this.MakeHttpCall(day, month, year);
            }
        },
        MakeHttpCall(day, month, year) {
            try {
                axios.get(`https://holidays.abstractapi.com/v1/?api_key=53e399a70ef84765b4a5a5d5bac08a25&country=US&day=${day}&month=${month}&year=${year}`)
                    .then(response => {
                        let rawArray = response.data;
                        //console.log(rawArray);
                        let tempArray = [];
                        for (let index = 0; index < rawArray.length; index++) {
                            const element = rawArray[index];
                            //console.log('holiday name: ' + element.name);
                            let holiday = { name: element.name, date: element.date, country: element.country };
                            if (!tempArray.some((obj) => obj.name == holiday.name)) {
                                tempArray.push(holiday);
                                console.log('Single holiday: ' + holiday);
                            }
                        }
                        this.holidays = tempArray;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } catch (error) {
                console.log("Holiday error: " + error);
            }

        }
    },
    mounted() {
        let apikey = this.$cookies.get(Constants.ABSTRACT_HOLIDAY_API_KEY);
        if (apikey) {
            this.getHolidays();
        }
        else {
            console.log('NOT fetching Holidays');
        }
    }
}
</script>