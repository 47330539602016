<template>
    <div>
        <h3>Pubg Lifetime Stats</h3>
        <div v-if="stats"></div>
        <div v-else></div>
        <p>{{ stats.name }}</p>
        <div class="stats-container">
            <div class="stat-box" v-if="stats.soloTpp">
                <p>Solo TPP</p>
                <ul>
                    <li>Wins: {{ stats.soloTpp.wins }}</li>
                    <li>Kills: {{ stats.soloTpp.kills }}</li>
                    <li>Assists: {{ stats.soloTpp.assists }}</li>
                    <li>Team Kills: {{ stats.soloTpp.teamKills }}</li>
                    <li>Tops 10s: {{ stats.soloTpp.top10s }}</li>
                </ul>
            </div>
            <div class="stat-box" v-if="stats.soloFpp">
                <p>Solo FPP</p>
                <ul>
                    <li>Wins: {{ stats.soloFpp.wins }}</li>
                    <li>Kills: {{ stats.soloFpp.kills }}</li>
                    <li>Assists: {{ stats.soloFpp.assists }}</li>
                    <li>Team Kills: {{ stats.soloFpp.teamKills }}</li>
                    <li>Tops 10s: {{ stats.soloFpp.top10s }}</li>
                </ul>
            </div>
            <div class="stat-box" v-if="stats.duoTpp">
                <p>Duo TPP</p>
                <ul>
                    <li>Wins: {{ stats.duoTpp.wins }}</li>
                    <li>Kills: {{ stats.duoTpp.kills }}</li>
                    <li>Assists: {{ stats.duoTpp.assists }}</li>
                    <li>Team Kills: {{ stats.duoTpp.teamKills }}</li>
                    <li>Tops 10s: {{ stats.duoTpp.top10s }}</li>
                </ul>
            </div>
            <div class="stat-box" v-if="stats.duoFpp">
                <p>Duo FPP</p>
                <ul>
                    <li>Wins: {{ stats.duoFpp.wins }}</li>
                    <li>Kills: {{ stats.duoFpp.kills }}</li>
                    <li>Assists: {{ stats.duoFpp.assists }}</li>
                    <li>Team Kills: {{ stats.duoFpp.teamKills }}</li>
                    <li>Tops 10s: {{ stats.duoFpp.top10s }}</li>
                </ul>
            </div>
            <div class="stat-box" v-if="stats.squadTpp">
                <p>Squad TPP</p>
                <ul>
                    <li>Wins: {{ stats.squadTpp.wins }}</li>
                    <li>Kills: {{ stats.squadTpp.kills }}</li>
                    <li>Assists: {{ stats.squadTpp.assists }}</li>
                    <li>Team Kills: {{ stats.squadTpp.teamKills }}</li>
                    <li>Tops 10s: {{ stats.squadTpp.top10s }}</li>
                </ul>
            </div>
            <div class="stat-box" v-if="stats.squadFpp">
                <p>Squad FPP</p>
                <ul>
                    <li>Wins: {{ stats.squadFpp.wins }}</li>
                    <li>Kills: {{ stats.squadFpp.kills }}</li>
                    <li>Assists: {{ stats.squadFpp.assists }}</li>
                    <li>Team Kills: {{ stats.squadFpp.teamKills }}</li>
                    <li>Tops 10s: {{ stats.squadFpp.top10s }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>


<script>
import Constants from '@/data/contants';
import axios from 'axios';

export default {
    name: "PubgBoard",
    data() {
        return {
            stats: "",
        }
    },
    methods: {
        async getStats() {
            let pubgApiKey = this.$cookies.get(Constants.PUBG_API_COOKIE_NAME);
            let pubgPlayerName = this.$cookies.get(Constants.PUBG_PLAYER_COOKIE_NAME);
            if (pubgApiKey && pubgPlayerName) {
                const options = {
                    method: 'GET',
                    url: `https://api.pubg.com/shards/steam/players?filter[playerNames]=${pubgPlayerName}`,
                    headers: {
                        'Authorization': "Bearer " + pubgApiKey,
                        'Accept': 'application/json',
                    }
                };
                console.log('Options url: ' + options.url);
                try {
                    let response = await axios.request(options);
                    console.log('Pubg response: ' + response);
                    let selfUrl = response.data.data[0].links.self;
                    let accountId = response.data.data[0].id;
                    console.log('Pubg self: ' + selfUrl);
                    const selfOptions = {
                        method: 'GET',
                        url: `https://api.pubg.com/shards/steam/players/${accountId}/seasons/lifetime?filter[gamepad]=false`,
                        headers: {
                            'Authorization': "Bearer " + pubgApiKey,
                            'Accept': 'application/json',
                        }
                    };
                    let selfResponse = await axios.request(selfOptions);
                    this.stats = selfResponse;
                    if (selfResponse) {
                        let gameData = selfResponse.data.data.attributes.gameModeStats;
                        let soloTpp = { wins: gameData.solo.wins, kills: gameData.solo.kills, assists: gameData.solo.assists, teamKills: gameData.solo.teamKills, top10s: gameData.solo.top10s, };
                        let soloFpp = { wins: gameData['solo-fpp'].wins, kills: gameData['solo-fpp'].kills, assists: gameData['solo-fpp'].assists, teamKills: gameData['solo-fpp'].teamKills, top10s: gameData['solo-fpp'].top10s, };
                        let duoTpp = { wins: gameData.duo.wins, kills: gameData.duo.kills, assists: gameData.duo.assists, teamKills: gameData.duo.teamKills, top10s: gameData.duo.top10s, };
                        let duoFpp = { wins: gameData['duo-fpp'].wins, kills: gameData['duo-fpp'].kills, assists: gameData['duo-fpp'].assists, teamKills: gameData['duo-fpp'].teamKills, top10s: gameData['duo-fpp'].top10s, };
                        let squadTpp = { wins: gameData.squad.wins, kills: gameData.squad.kills, assists: gameData.squad.assists, teamKills: gameData.squad.teamKills, top10s: gameData.squad.top10s, };
                        let squadFpp = { wins: gameData['squad-fpp'].wins, kills: gameData['squad-fpp'].kills, assists: gameData['squad-fpp'].assists, teamKills: gameData['squad-fpp'].teamKills, top10s: gameData['squad-fpp'].top10s, };
                        this.stats = { name: pubgPlayerName, soloTpp: soloTpp, soloFpp: soloFpp, duoTpp: duoTpp, duoFpp: duoFpp, squadTpp: squadTpp, squadFpp: squadFpp };
                    } else {
                        console.log("Error getting PUBG self data");
                    }
                } catch (error) {
                    console.log('Error getting Pubg stats: ' + error);
                }
            } else {
                console.log("Not fetching PUBG stats");
            }
        },
    },
    mounted() {
        this.getStats()
        setInterval(this.getStats, 600000);
    }
}
</script>



<style scoped>
.stats-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.stat-box {
    width: calc(33.33% - 10px);
    /* 3 columns with some space */
    margin-bottom: 20px;
    /* Space between rows */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.stat-box p {
    font-weight: bold;
}
</style>