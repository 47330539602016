<template>
    <div>
        <h3>Space Flight News</h3>
        <h4 v-if="this.flight.title">{{ this.flight.title }}</h4>
        <img :src=this.flight.image style="width: 90%;" />
        <p v-if="this.flight.summary">Description: {{ this.flight.summary }}</p>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    name: "SpaceFlight",
    data() {
        return {
            flight: ""
        }
    },
    methods: {
        async getArticle() {
            try {
                let offset = Math.floor(Math.random() * 20000);
                console.log('flight offset: ' + offset);
                let response = await axios.get(`https://api.spaceflightnewsapi.net/v4/articles/?limit=1&offset=${offset}`);
                console.log("Flight: " + response.data.results[0].title);
                let article = response.data.results[0];
                this.flight = { title: article.title, summary: article.summary, image: article.image_url };
            } catch (error) {
                console.log("Flight error: " + error);
            }
        },
    },
    mounted() {
        this.getArticle()
        setInterval(this.getArticle, 600000);
    }
}
</script>