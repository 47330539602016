<template>
    <div>
        <h3>Current News</h3>
        <h4 v-if="this.news.title">{{ this.news.title }}</h4>
        <img :src=this.news.image style="width: 90%;" />
        <p v-if="this.news.summary">Description: {{ this.news.summary }}</p>
        <p v-if="this.news.url"><a :href=this.news.url>Source</a></p>
    </div>
</template>


<script>
import axios from 'axios';
import Contants from '@/data/contants';

export default {
    name: "CurrentNews",
    data() {
        return {
            news: ""
        }
    },
    methods: {
        async getNews() {
            try {
                let apiKey = this.$cookies.get(Contants.CURRENTS_NEWS_COOKIE_NAME);
                if (apiKey) {
                    const response = await axios.get('https://api.currentsapi.services/v1/latest-news', {
                        params: {
                            apiKey: apiKey,
                        },
                    });

                    if (response.data.status == "ok") {
                        let article = response.data.news[0];
                        //console.log("News: " + article);
                        this.news = { title: article.title, description: article.description, url: article.url, image: article.image };
                    } else {
                        console.log("Bad status from News API");
                    }
                } else {
                    console.log('NOT fetching Current News');
                }


            } catch (error) {
                console.log("News error: " + error);
            }
        },
    },
    mounted() {
        this.getNews()
        setInterval(this.getNews, 600000);
    }
}
</script>