<template>
    <div>
        <p>IP Address: {{ this.ipAddress.ip }} - Country: {{ this.ipAddress.country }}</p>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    name: "IpAddress",
    data() {
        return {
            ipAddress: ""
        }
    },
    methods: {
        async getIp() {
            try {
                let response = await axios.get('https://speed.cloudflare.com/meta');
                //console.log("IP: " + response);
                let ip = response.data.clientIp;
                let country = response.data.country;
                console.log(ip);
                console.log(country);
                this.ipAddress = { ip, country };
            } catch (error) {
                console.log("Error getting IP: " + error);
            }
        },
    },
    mounted() {
        this.getIp()
    }
}
</script>