import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import VueCookies from 'vue-cookies';

import MainBoard from './components/MainBoard.vue'
import SetupConfiguration from './components/SetupConfiguration.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: MainBoard },
        { path: '/configuration', component: SetupConfiguration },
    ]
});

const app = createApp(App)

app.use(router);
app.use(VueCookies);
app.mount('#app')