<template>
    <div>
        <h3>Game Deal</h3>
        <div>
            <h4 v-if="this.deal.title">{{ this.deal.title }}</h4>
            <img :src=this.deal.image style="width: 90%;" />
            <p v-if="this.deal.price">Sale Price: {{ this.deal.price }}</p>
            <p v-if="this.deal.normalPrice">Normal Price: {{ this.deal.normalPrice }}</p>
            <p v-if="this.deal.releaseDate">Release Date: {{ this.deal.releaseDate }}</p>
            <p v-if="this.deal.steamRating">Steam Rating: {{ this.deal.steamRating }}</p>
            <p v-if="this.deal.storeName">Store: {{ this.deal.storeName }}</p>
            <img v-if="this.deal.storeImage" :src=this.deal.storeImage style="width: 10%;" />
        </div>
        <p>*Game deals pulled from Cheapshark API</p>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    name: "GameDeals",
    data() {
        return {
            deal: ""
        }
    },
    methods: {
        async getDeal() {
            try {
                let response = await axios.get('https://www.cheapshark.com/api/1.0/deals?pageSize=1&sortBy=Recent');
                //console.log("Game: " + response.data);
                let game = response.data[0];
                let utcSeconds = game.releaseDate;
                let release = new Date(0); // The 0 there is the key, which sets the date to the epoch
                release.setUTCSeconds(utcSeconds);
                const date = release.toLocaleString([], {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                });
                let storeID = game.storeID;
                let gameStore = '';
                if (storeID) {
                    try {
                        let response = await axios.get('https://www.cheapshark.com/api/1.0/stores');
                        let rawStores = response.data;
                        //console.log('Raw Store: ' + rawStores);
                        let allStores = [];
                        for (let index = 0; index < rawStores.length; index++) {
                            const element = rawStores[index];
                            //console.log('Store: ' + element);
                            allStores.push({ storeID: element.storeID, storeName: element.storeName, image: `https://www.cheapshark.com${element.images.logo}` });
                        }
                        gameStore = allStores.find(x => x.storeID == storeID);
                        //console.log(gameStore);
                    } catch (error) {
                        console.log('Error getting store id: ' + error);
                    }
                }
                this.deal = { title: game.title, price: game.salePrice, normalPrice: game.normalPrice, steamRating: game.steamRating, releaseDate: date, image: game.thumb, storeName: gameStore.storeName, storeImage: gameStore.image };
            } catch (error) {
                console.log('Game deal error: ' + error);
            }
        },
    },
    mounted() {
        this.getDeal()
        setInterval(this.getDeal, 600000);
    }
}
</script>